<script>
export default {
	inject: ['xcheckout'],
	props: ['data'],
	computed: {
		checkout() {
			return this.xcheckout()
		},
	},
}
</script>

<template>
	<CardLayout @cardClick="$emit('optionSelected')" clickable>
		<CreditCardCheckoutOptionContent :checkout="checkout" :order="checkout.order" />
	</CardLayout>
</template>
