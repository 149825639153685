<script>
import { componentExists } from '@/site/autoloader'

export default {
	lang: 'shop',
	inject: ['xcheckout'],
	computed: {
		checkout() {
			return this.xcheckout()
		},
	},
	methods: {
		compName(method) {
			console.log(method)
			return `${method.methodKey}-Payment-CheckoutOptions`
		},
		compExists(method) {
			return componentExists(this.compName(method))
		},
	},
	mounted() {
		this.$nextTick(() => {
			console.log(this.checkout.stepViewData)
		})
	},
}
</script>

<template>
	<CheckoutStepLayout :title="$lang('Elige la forma de pago')">
		<div v-for="(method, i) in checkout.stepViewData.paymentMethods" :key="i">
			<component
				v-if="compExists(method)"
				:is="compName(method)"
				:data="method.checkoutData"
				@optionSelected="checkout.stepSubmit({ methodKey: method.methodKey, input: $event })"
			/>
		</div>
	</CheckoutStepLayout>
</template>

<style></style>
