<script>
import { get } from 'vuex-pathify'
import addressHelperMixin from '@/__shared/shop/mixin_address-helper.js'

export default {
	lang: 'shop',
	mixins: [addressHelperMixin],
	inject: ['xcheckout'],
	data() {
		return {
			buyer: {},
			invoiceId: null,
			invoices: [],
			invoice: {},
			invoiceAddressLine: null,
			invoiceAddress: null,
			addressDialog: false,
			validation: {},
			forceExportInvoice: false,
		}
	},
	computed: {
		checkout() {
			return this.xcheckout()
		},
		user: get('shop/user'),
		asCard() {
			return this.invoices.length > 0
		},
		countriesFilter() {
			return this.forceExportInvoice ? (country) => country.iso != 'AR' : (country) => country.iso == 'AR'
		},
	},
	eventer: {
		checkoutStepLoaded() {
			let payload = this.checkout.stepsData.contact.payload || {}
			let viewData = this.checkout.stepViewData
			this.forceExportInvoice = viewData.forceExportInvoice
			this.buyer = payload.buyer || {
				email: this.user.contactEmail,
				firstname: this.user.firstname,
				lastname: this.user.lastname,
				phoneCountryId: this.user.phoneCountryId,
				phoneAreaCode: this.user.phoneAreaCode,
				phoneNumber: this.user.phoneNumber,
			}
			this.invoices = (viewData.invoices || []).filter((invoice) => invoice.invoiceType != 'E')
			this.invoiceAddress = payload.invoiceAddress
			if (payload.invoiceId && this.invoices.find(({ id }) => id == payload.invoiceId)) {
				this.invoiceId = payload.invoiceId
			} else if (this.invoices.length) {
				this.invoiceId = this.invoices[0].id
			} else {
				this.invoice = {
					business: false,
					personFirstname: this.user.firstname,
					personLastname: this.user.lastname,
				}
			}
		},
	},
	watch: {
		'invoice.business'() {
			this.$nextTick(() => {
				this.checkout.validation = { ...this.checkout.validation }
			})
		},
		invoiceAddress(value) {
			if (value) this.invoiceAddressLine = this.makeAddressLine(value)
		},
	},
	methods: {
		submit() {
			return this.checkout.stepSubmit({
				buyer: this.buyer,
				invoiceId: this.invoiceId,
				invoice: this.invoice,
				invoiceAddress: this.invoiceAddress,
			})
		},
	},
}
</script>

<template>
	<CheckoutStepLayout :title="$lang('Contacto y Facturación')">
		<form @submit.prevent="submit()">
			<Validator :validation="checkout.validation">
				<CardLayout>
					<div class="font-weight-bold pb-3">{{ 'Datos de Contacto' | lang }}</div>
					<v-row>
						<v-col cols="12">
							<TextField
								label="Email"
								v-model="buyer.email"
								:hint="
									$lang(
										'Ingresa una casilla de correo que uses habitualmente para recibir las notificaciones sobre el estado de tu compra'
									)
								"
								persistent-hint
							/>
						</v-col>
						<v-col cols="12" md="6">
							<TextField :label="$lang('Nombre')" v-model="buyer.firstname" />
						</v-col>
						<v-col cols="12" md="6">
							<TextField :label="$lang('Apellido')" v-model="buyer.lastname" />
						</v-col>
						<v-col cols="12" md="6">
							<CountryPhoneCodeSelector v-model="buyer.phoneCountryId" />
						</v-col>
						<v-col cols="4" md="2">
							<TextField :label="$lang('Cód. Área')" type="tel" v-model="buyer.phoneAreaCode" />
						</v-col>
						<v-col cols="8" md="4">
							<TextField
								:label="$lang('Teléfono')"
								placeholder="11111111"
								type="tel"
								v-model="buyer.phoneNumber"
							/>
						</v-col>
					</v-row>
					<template v-if="!forceExportInvoice">
						<v-divider class="my-4"></v-divider>
						<div class="font-weight-bold pb-3">{{ 'Datos de Facturación' | lang }}</div>
						<v-radio-group
							v-model="invoiceId"
							v-if="invoices.length"
							hide-details="auto"
							class="mt-0"
							mandatory
						>
							<v-radio v-for="inv of invoices" :key="inv.id" :value="inv.id" class="pb-3">
								<template #label>
									<div v-if="inv.business">
										{{ inv.businessName }}<br />
										CUIT {{ inv.businessIdNumber }}<br />
										{{ 'Factura' | lang }} {{ inv.invoiceType }}<br />
									</div>
									<div v-else>
										{{ inv.personLastname }}, {{ inv.personFirstname }}<br />
										DNI {{ inv.personIdNumber }}<br />
									</div>
								</template>
							</v-radio>
							<v-radio :label="$lang('Usar otros datos de facturación')" :value="0" />
						</v-radio-group>
						<v-card v-show="!invoiceId" :class="{ 'mt-4 pa-10': asCard }" :elevation="asCard ? 4 : 0">
							<v-btn-toggle v-model="invoice.business" mandatory class="mb-4">
								<Button color="primary" text :value="false" small> {{ 'PERSONA' | lang }} </Button>
								<Button color="primary" text :value="true" small> {{ 'EMPRESA' | lang }} </Button>
							</v-btn-toggle>
							<v-row v-if="invoice.business">
								<v-col cols="12">
									<TextField :label="$lang('Razón Social')" v-model="invoice.businessName" />
								</v-col>
								<v-col cols="12">
									<TextField
										label="CUIT"
										placeholder="XX-XXXXXXXX-X"
										v-model="invoice.businessIdNumber"
									/>
								</v-col>
								<v-col cols="12">
									<v-radio-group
										v-model="invoice.invoiceType"
										row
										mandatory
										hide-details="auto"
										:error-messages="validation['invoice.invoiceType']"
									>
										<v-radio :label="$lang('Factura A')" value="A" />
										<v-radio :label="$lang('Factura B')" value="B" />
									</v-radio-group>
								</v-col>
							</v-row>
							<v-row v-if="!invoice.business">
								<v-col cols="12" md="6">
									<TextField :label="$lang('Nombre')" v-model="invoice.personFirstname" />
								</v-col>
								<v-col cols="12" md="6">
									<TextField :label="$lang('Apellido')" v-model="invoice.personLastname" />
								</v-col>
								<v-col cols="12">
									<TextField label="DNI" v-model="invoice.personIdNumber" />
								</v-col>
							</v-row>
						</v-card>
					</template>
					<v-divider class="my-4"></v-divider>
					<div class="font-weight-bold pb-3">{{ 'Dirección de Facturación' | lang }}</div>
					<v-row>
						<v-col cols="12">
							<Textarea
								:label="$lang('Dirección de facturación')"
								:placeholder="$lang('Seleccionar...')"
								rows="1"
								auto-grow
								readonly
								no-resize
								v-model="invoiceAddressLine"
								@click="addressDialog = true"
								validator-key="invoiceAddress"
								class="invoice-address-textarea"
							/>
							<div class="text-right mt-1">
								<Button small text @click="addressDialog = true">
									{{ 'Seleccionar' | lang }}
								</Button>
							</div>
						</v-col>
					</v-row>
					<div class="d-flex pt-6">
						<v-spacer />
						<Button @click="submit()" x-large color="success" :loading="checkout.loadingSubmit">
							{{ 'Continuar' | lang }}
						</Button>
					</div>
				</CardLayout>
			</Validator>
		</form>
		<AddressDialog
			v-model="addressDialog"
			@addressSelected="invoiceAddress = $event"
			:title="$lang('Confirma la dirección de facturación')"
			:countries-filter="countriesFilter"
		/>
	</CheckoutStepLayout>
</template>

<style scoped>
.invoice-address-textarea ::v-deep textarea {
	line-height: 1.1;
	padding-top: 10px;
	padding-bottom: 10px;
}
</style>
