<script>
import logo from './assets/transbanklogo.svg'
export default {
	lang: 'shop',
	data() {
		return {
			logo,
		}
	},
}
</script>

<template>
	<CheckoutPaymentCardContent
		:logo-src="logo"
		:logo-width="100"
		:logo-height="100"
		:title="$lang('Rápido y seguro.')"
	>
	</CheckoutPaymentCardContent>
</template>
