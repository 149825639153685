<script>
import { componentExists } from '@/site/autoloader'

export default {
	lang: 'shop',
	inject: ['xcheckout'],
	data() {
		return {
			couponCode: null,
			customDiscountsOptions: [],
			loading: false,
		}
	},
	computed: {
		checkout() {
			return this.xcheckout()
		},
	},
	eventer: {
		checkoutStepLoaded() {
			this.customDiscountsOptions = this.checkout.stepViewData.customDiscountsOptions.filter((option) => {
				return componentExists(this.getCustomCardName(option.key))
			})
			this.couponCode = this.checkout.stepsData.discount.payload?.couponCode || null
		},
	},
	methods: {
		getCustomCardName(key) {
			return `CheckoutCustomDiscountCard-${key}`
		},
		async submitNoCode() {
			await this.checkout.stepSubmit({ noCode: true })
		},
		async submitCode() {
			this.loading = true
			await this.checkout.stepSubmit({ couponCode: this.couponCode })
			this.loading = false
		},
	},
}
</script>

<template>
	<CheckoutStepLayout :title="$lang('Aplica descuentos')">
		<CardLayout @cardClick="submitNoCode()" clickable>
			<div class="font-weight-bold">{{ 'Continuar' | lang }}</div>
		</CardLayout>
		<CardLayout :clickable="false">
			<div class="font-weight-bold pb-2">{{ 'Cupón de descuento' | lang }}</div>
			<div class="pb-4">
				{{ 'Ingresa tu código de descuento.' | lang }}<br />
				<small>{{ 'No acumulable con otras promociones' | lang }}</small>
			</div>
			<div>
				<v-form @submit.prevent="submitCode()">
					<Validator :validation="checkout.validation">
						<v-row>
							<v-col cols="12">
								<TextField
									v-model="couponCode"
									:label="$lang('Código del cupón')"
									:placeholder="$lang('Ingresa el código del cupón')"
								/>
							</v-col>
							<v-col cols="12" class="d-flex">
								<v-spacer />
								<Button color="success" @click="submitCode()" :loading="loading">
									{{ 'Aplicar' | lang }}
								</Button>
							</v-col>
						</v-row>
					</Validator>
				</v-form>
			</div>
		</CardLayout>
		<component
			v-for="({ key, data }, i) in customDiscountsOptions"
			:key="i"
			:is="getCustomCardName(key)"
			:data="data"
		/>
	</CheckoutStepLayout>
</template>

<style></style>
