<script>
import addressHelperMixin from '@/__shared/shop/mixin_address-helper.js'

export default {
	lang: 'shop',
	name: 'AddressDialog',
	mixins: [addressHelperMixin],
	props: {
		dialog: Boolean,
		title: String,
		countriesFilter: Function,
	},
	model: {
		prop: 'dialog',
		event: 'updateDialog',
	},
	data() {
		return {
			loading: false,
			loadingActions: false,
			loadingZipcode: false,
			address: {},
			validation: {},
			selectedAddress: null,
			addresses: [],
			addressForm: false,
			country: null,
			stateLoading: null,
		}
	},
	watch: {
		dialog(value) {
			if (value) this.loadAddresses()
		},
		country(value) {
			if (value) this.findState()
		},
	},
	computed: {
		dialogWidth() {
			if (this.loading) return 500
			if (this.addressForm) return 900
			return 700
		},
		dialogTitle() {
			if (this.addressForm) {
				return this.address.id ? this.$lang('Modificar dirección') : this.$lang('Crear nueva dirección')
			} else {
				return this.title || this.$lang('Selecciona la dirección')
			}
		},
		xs() {
			return this.$vuetify.breakpoint.xs
		},
		fixedState() {
			return this.country?.zipcodeValidation == 1
		},
	},
	methods: {
		accept() {
			if (this.addressForm) {
				this.saveAddress()
				//if (this.address.zipcodeId) this.saveAddress()
				//else this.searchZipcode()
			} else {
				this.$emit('addressSelected', this.selectedAddress)
				this.closeDialog()
			}
		},
		cancel() {
			if (this.addressForm && this.addresses.length) {
				this.addressForm = false
			} else {
				this.closeDialog()
			}
		},
		closeDialog() {
			this.$emit('updateDialog', false)
		},
		selectAddress(address) {
			this.$emit('addressSelected', address)
			this.closeDialog()
		},
		createAddress() {
			this.addressForm = true
			this.address = {}
		},
		editAddress(address) {
			this.addressForm = true
			this.address = {
				id: address.id,
				userId: address.userId,
				countryId: address.zipcode.state.country.id,
				zipcode: address.zipcode.code,
				state: address.zipcode.state.name,
				city: address.city,
				street: address.street,
				streetNumber: address.streetNumber,
				floor: address.floor,
				apartment: address.apartment,
				intersection1: address.intersection1,
				intersection2: address.intersection2,
				comment: address.comment,
			}
		},
		async loadAddresses(withLoader = true) {
			await this.$shopApi.get({
				url: '/user/addresses',
				loading: (v) => (this.loading = withLoader ? v : false),
				onSuccess: ({ data }) => {
					let addresses = data.addresses || []
					if (this.countriesFilter) {
						addresses = addresses.filter((address) =>
							this.countriesFilter(address.zipcode.state.country)
						)
					}
					this.addresses = addresses
				},
			})
			if (this.addresses.length) {
				this.selectedAddress = this.addresses[0]
				this.addressForm = false
			} else {
				this.createAddress()
			}
		},
		saveAddress() {
			let isNew = !this.address.id
			let options = {
				data: this.address,
				loading: (v) => (this.loadingActions = v),
				onValidation: ({ validation }) => (this.validation = validation),
				onSuccess: async ({ data, options }) => {
					this.addressForm = false
					await this.loadAddresses()
					this.selectedAddress = this.addresses.find(({ id }) => id == data.address.id)
					if (isNew) this.accept()
				},
			}
			if (isNew) this.$shopApi.post('/user/address', options)
			else this.$shopApi.put(`/user/address/${this.address.id}`, options)
		},
		findState() {
			this.$set(this.validation, 'address.zipcode', null)
			if (!this.fixedState || !this.address.countryId || !this.address.zipcode) return
			this.$shopApi.get(`/location/state-from-zipcode`, {
				query: {
					countryId: this.address.countryId,
					zipcode: this.address.zipcode,
				},
				loading: (v) => (this.stateLoading = v),
				done: ({ success, data }) => {
					if (success) {
						this.address.state = data.state.name
					} else {
						this.address.state = null
						this.$set(this.validation, 'address.zipcode', this.$lang('El código postal es inválido'))
					}
				},
			})
		},
		setZipcodeId(value) {
			this.$set(this.address, 'zipcodeId', value)
		},
		searchZipcode() {
			this.$shopApi.get({
				url: `/user/zipcode/${this.address.zipcodeInput}`,
				loading: (v) => {
					this.loadingActions = this.loadingZipcode = v
				},
				onValidation: ({ validation }) => (this.validation = validation),
				onSuccess: ({ data }) => {
					this.setZipcodeId(data.zipcode.id)
					this.address.stateName = data.zipcode.state.name
					this.address.countryId = data.zipcode.state.country.id
				},
			})
		},
	},
	mounted() {
		if (this.dialog) this.loadAddresses()
	},
}
</script>

<template>
	<v-dialog :value="dialog" :max-width="dialogWidth + 'px'" persistent scrollable v-bind="$attrs">
		<!-- Loading Card -->
		<v-card v-if="loading" class="py-4">
			<v-card-title>
				<v-progress-circular indeterminate size="30" class="mr-4" />
				{{ 'Cargando tus direcciones' | lang }}
			</v-card-title>
		</v-card>
		<!-- Loaded Card -->
		<v-card v-else>
			<!-- dynamic title -->
			<v-card-title>
				<div class="d-flex w100 align-start">
					<span class="font-3">{{ dialogTitle }}</span>
					<template v-if="xs">
						<v-spacer />
						<Button icon @click="cancel" class="mb-3 mb-sm-0" :disabled="loadingActions">
							<v-icon>mdi-close</v-icon>
						</Button>
					</template>
				</div>
			</v-card-title>
			<!-- User Addresses selectors radios -->
			<v-card-text style="max-height: 1000px" v-if="!addressForm">
				<v-radio-group v-model="selectedAddress">
					<v-radio
						v-for="address in addresses"
						:key="address.id"
						:value="address"
						class="py-3 align-start"
					>
						<template #label>
							<div>
								{{ makeAddressLine(address) }}
								<div v-if="xs" class="text-right pt-3">
									<Button color="primary" small outlined block @click="editAddress(address)">
										<v-icon class="mr-1" small>mdi-pencil</v-icon> {{ 'Editar' | lang }}
									</Button>
								</div>
							</div>
							<template v-if="!xs">
								<v-spacer />
								<Button color="primary" small @click="editAddress(address)" outlined class="ml-4">
									<v-icon class="mr-1" small>mdi-pencil</v-icon> {{ 'Editar' | lang }}
								</Button>
							</template>
						</template>
					</v-radio>
				</v-radio-group>
			</v-card-text>
			<!-- Address Edit/Create Form -->
			<v-card-text style="max-height: 1000px" class="pt-4" v-if="addressForm">
				<Validator :validation="validation">
					<div>
						<v-row>
							<v-col cols="12" sm="6">
								<CountrySelector
									:label="$lang('País')"
									v-model="address.countryId"
									@country-selected="country = $event"
									:countries-filter="countriesFilter"
								/>
							</v-col>
							<v-col cols="12" sm="6">
								<TextField
									ref="zipcodeTextField"
									:label="$lang('Código Postal')"
									v-model="address.zipcode"
									:loading="loadingZipcode"
									:style="{ width: $vuetify.breakpoint.smAndUp ? '200px' : '' }"
									@change="findState()"
								/>
							</v-col>
							<v-col cols="12" sm="6">
								<TextField
									:label="$lang('Estado / Provincia')"
									v-model="address.state"
									:readonly="fixedState"
									:loading="stateLoading"
								/>
							</v-col>
							<v-col cols="12" sm="6">
								<TextField :label="$lang('Ciudad / Localidad')" v-model="address.city" />
							</v-col>
							<v-col cols="12" md="6">
								<TextField :label="$lang('Calle')" v-model="address.street" />
							</v-col>
							<v-col cols="12" sm="4" md="2">
								<TextField :label="$lang('Altura')" v-model="address.streetNumber" />
							</v-col>
							<v-col cols="6" sm="4" md="2">
								<TextField :label="$lang('Piso')" v-model="address.floor" />
							</v-col>
							<v-col cols="6" sm="4" md="2">
								<TextField :label="$lang('Departamento')" v-model="address.apartment" />
							</v-col>
							<v-col cols="12" sm="6">
								<TextField
									:label="$lang('Entrecalle 1')"
									v-model="address.intersection1"
									:hint="$lang('Opcional')"
									persistent-hint
								/>
							</v-col>
							<v-col cols="12" sm="6">
								<TextField
									:label="$lang('Entrecalle 2')"
									v-model="address.intersection2"
									:hint="$lang('Opcional')"
									persistent-hint
								/>
							</v-col>
							<v-col cols="12">
								<TextField
									:label="$lang('Comentarios / Instrucciones')"
									v-model="address.comment"
									:hint="$lang('Opcional')"
									persistent-hint
								/>
							</v-col>
						</v-row>
					</div>
				</Validator>
			</v-card-text>
			<!-- Bottom Dynamic Actions -->
			<v-card-actions class="flex-wrap">
				<Button
					small
					outlined
					color="primary"
					@click="createAddress()"
					class="mb-3 mb-sm-0"
					v-if="!addressForm"
				>
					<v-icon>mdi-plus</v-icon> {{ xs ? $lang('Agregar') : $lang('Nueva dirección') }}
				</Button>
				<v-spacer></v-spacer>
				<Button text @click="cancel" class="mb-3 mb-sm-0" :disabled="loadingActions" v-if="!xs">
					{{ 'Cancelar' | lang }}
				</Button>
				<Button color="success" @click="accept" class="mb-3 mb-sm-0" :loading="loadingActions">
					{{ addressForm ? $lang('Aceptar') : $lang('Seleccionar') }}
				</Button>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<style scoped>
.v-dialog__content ::v-deep .v-dialog {
	margin: 12px;
}
</style>
