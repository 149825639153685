<script>
export default {
	lang: 'shop',
	metaInfo() {
		return {
			title: this.$lang('Pago con Tarjeta'),
		}
	},
	pageView: true,
	data() {
		return {
			order: null,
			loading: false,
			options: null,
			selectionConfirmed: false,
			selection: {
				brand: null,
				entity: null,
				plan: null,
			},
			savedForm: {},
			invalidPlan: false,
		}
	},
	computed: {
		isMobile() {
			return this.$vuetify.breakpoint.xs
		},
		orderId() {
			return this.$route.params.id
		},
		currencySign() {
			return this.order?.currency.sign
		},
	},
	methods: {
		async loadPaymentOptions() {
			await this.$shopApi.get({
				url: `/credit-card/order-payment-options/${this.orderId}`,
				loading: (v) => (this.loading = v),
				done: ({ success, data, message }) => {
					if (success) {
						this.order = data.order
						this.options = data.options
					} else {
						if (message.code === 'ORDER_NOT_FOUND') {
							this.$router.push({ name: 'user.orders' })
						} else {
							this.$router.push({ name: 'user.order-detail', params: { id: this.orderId } })
						}
					}
				},
			})
		},
		onInvalidPlan() {
			this.invalidPlan = true
		},
		onPlanBoxClick() {
			this.selectionConfirmed = false
			if (this.invalidPlan) {
				this.$nextTick(() => {
					this.selection = {
						brand: null,
						entity: null,
						plan: null,
					}
					this.invalidPlan = false
					this.loadPaymentOptions()
				})
			}
		},
	},
	created() {
		this.loadPaymentOptions()
	},
}
</script>

<template>
	<div>
		<Container class="pa-4 pa-sm-8" width="660" :fluid="{ xs: true, sm: false }">
			<v-card class="pa-6">
				<div class="d-flex align-start pb-4" v-if="!loading">
					<div class="font-1 grey--text">{{ 'Orden' | lang }} #{{ order.code }}</div>
					<v-spacer />
					<div class="font-2">
						{{ 'Total' | lang }} <b><PriceText :amount="order.total" :currency="currencySign" /></b>
					</div>
				</div>
				<div v-show="!selectionConfirmed">
					<CreditCardPlanSelector
						:loading="loading"
						:currency-sign="currencySign"
						:options="options"
						@selection-changed="selection = $event"
					/>
					<div class="d-flex justify-end" v-if="!loading">
						<Button
							:block="isMobile"
							:disabled="!selection.plan"
							class="mt-3"
							color="link"
							@click="selectionConfirmed = true"
						>
							{{ 'Continuar' | lang }}
						</Button>
					</div>
				</div>
				<div v-if="selectionConfirmed" class="pt-3 pb-6">
					<CreditCardPlanBox
						:order="order"
						:brand="selection.brand"
						:entity="selection.entity"
						:plan="selection.plan"
						@click="onPlanBoxClick"
						@click:action="onPlanBoxClick"
					/>
				</div>
				<div v-if="selectionConfirmed">
					<CreditCardNumberForm
						v-model="savedForm"
						:order="order"
						:brand="selection.brand"
						:entity="selection.entity"
						:plan="selection.plan"
						@invalid-plan="onInvalidPlan"
						@reset-plan="onPlanBoxClick"
					/>
				</div>
			</v-card>
		</Container>
	</div>
</template>
