<script>
import paypal from './assets/logo_paypal.svg'
import amex from './assets/logo_amex.svg'
import visa from './assets/logo_visa.svg'
import master from './assets/logo_master.svg'

export default {
	lang: 'shop',
	computed: {
		cardsLogos() {
			return [{ src: paypal, mw: 90 }, { src: visa }, { src: master }, { src: amex }]
		},
	},
}
</script>

<template>
	<div>
		<div class="font-weight-bold">
			{{ 'Puedes pagar con cualquiera de estos medios. Es rápido y seguro.' | lang }}
		</div>
		<v-list>
			<v-list-item class="pl-1" dense>
				<v-list-item-icon class="mr-0">
					<v-icon small>mdi-check</v-icon>
				</v-list-item-icon>
				<v-list-item-content class="pa-0">
					{{ 'Tu tarjeta de Crédito y Débito' | lang }}
				</v-list-item-content>
			</v-list-item>
			<v-list-item class="pl-1" dense>
				<v-list-item-icon class="mr-0">
					<v-icon small>mdi-check</v-icon>
				</v-list-item-icon>
				<v-list-item-content class="pa-0">
					{{ 'Dinero en cuenta de Paypal' | lang }}
				</v-list-item-content>
			</v-list-item>
		</v-list>
		<div class="d-flex flex-wrap pt-4">
			<Media
				v-for="logo in cardsLogos"
				:key="logo.src"
				:src="logo.src"
				:max-width="logo.mw || 45"
				:max-height="logo.mh || 30"
				class="mb-3 mr-3"
				contain
			/>
		</div>
	</div>
</template>
