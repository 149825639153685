<script>
export default {
	lang: 'shop',
	name: 'OrderSummaryMobile',
	props: {
		order: Object,
	},
	data() {
		return {
			dialog: false,
		}
	},
	methods: {
		toggleDialog() {
			this.dialog = !this.dialog
		},
	},
}
</script>

<template>
	<div>
		<div
			@click="toggleDialog"
			class="flex-nowrap text-right bottom-bar pa-3 font-3 d-flex flex-row justify-center align-center elevation-8"
			:style="{ 'z-index': dialog ? 999 : 1 }"
		>
			<template v-if="!dialog">
				<div class="d-flex flex-column px-5">
					<span class="font-weight-bold">{{ 'TOTAL' | lang }}</span>
					<PriceText :amount="order.total" class="font-5" :currency="order.currency.sign" />
				</div>
				<Button color="link" text>
					{{ 'Ver resumen' | lang }} <v-icon class="ml-1">mdi-chevron-up</v-icon>
				</Button>
			</template>
			<Button v-else color="link" text>
				{{ 'Cerrar' | lang }} <v-icon class="ml-1">mdi-chevron-down</v-icon>
			</Button>
		</div>
		<v-dialog
			v-model="dialog"
			v-if="order"
			fullscreen
			hide-overlay
			transition="dialog-bottom-transition"
			scrollable
			class="white"
		>
			<OrderSummary :order="order" />
			<slot name="below-summary" />
		</v-dialog>
	</div>
</template>

<style scoped>
.bottom-bar {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	background-color: #fff;
	cursor: pointer;
	border-top: 1px solid #ddd;
}
.v-card {
	box-shadow: none !important;
}
</style>
