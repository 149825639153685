<script>
export default {
	lang: 'shop',
	inject: ['xcheckout'],
	data() {
		return { loading: false }
	},
	computed: {
		checkout() {
			return this.xcheckout()
		},
	},
	methods: {
		async submit() {
			this.loading = true
			let { success, data } = await this.checkout.stepSubmit()
			if (success) {
				window.location = this.$router.resolve({
					name: 'creditCardPayment',
					params: { id: data.checkout.order.id },
				}).href
			} else {
				this.loading = false
			}
		},
	},
}
</script>
<template>
	<div>
		<Button @click="submit()" color="success" x-large block :loading="loading">
			{{ 'Pagar con Tarjeta' | lang }}
		</Button>
	</div>
</template>
