<script>
export default {
	props: {
		message: String,
	},
}
</script>

<template>
	<CheckoutPaymentCardContent icon="cash-fast" title="Pago en Efectivo">
		<div v-if="message" class="pt-2">{{ message }}</div>
	</CheckoutPaymentCardContent>
</template>
