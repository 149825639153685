<script>
import { loadScript } from '@paypal/paypal-js'

export default {
	lang: 'shop',
	inject: ['xcheckout'],
	computed: {
		checkout() {
			return this.xcheckout()
		},
	},
	data() {
		return {
			snackbar: false,
			error: {},
			onSnackbarClose: null,
			paypalLoading: false,
		}
	},
	watch: {
		snackbar(value) {
			if (value === false && this.onSnackbarClose) {
				this.onSnackbarClose()
				this.onSnackbarClose = null
			}
		},
	},
	methods: {
		async getClientId() {
			let { data } = await this.$shopApi.get('/paypal/client-id')
			return data.clientId
		},
		async loadPaypalButton() {
			this.paypalLoading = true
			let clientId = await this.getClientId()
			let currency = this.checkout.order.currency.iso
			let paypal = await loadScript({ 'client-id': clientId, currency })
			this.paypalLoading = false
			await paypal
				.Buttons({
					createOrder: async (paypalData, actions) => {
						let { success, data } = await this.$shopApi.post({
							url: `/paypal/create-order/${this.checkout.hash}`,
							loading: (v) => (this.checkout.loadingSubmit = v),
						})
						if (success) return data.paypalOrderId
						else throw null
					},

					// Call your server to finalize the transaction
					onApprove: async (paypalData, actions) => {
						let { success, data, message } = await this.checkout.stepSubmit({
							paypalOrderId: paypalData.orderID,
						})

						if (success) {
							this.checkout.loadingSubmit = true
							window.location = this.$router.resolve({
								name: 'user.order-detail',
								params: { id: data.checkout.order.id },
							}).href
						} else if (message.code == 'restart') {
							this.showError(
								{
									title: this.$lang('El pago fue rechazado. Por favor, intenta nuevamente.'),
									text: message.text,
								},
								() => actions.restart()
							)
						} else {
							if (!message.title) message.title = this.$lang('Ocurrió un error con tu pago.')
							this.showError(message)
						}
					},

					onError: (err) => {
						this.showError({
							title: this.$lang('Ocurrió un error con tu pago.'),
							text: err.message,
						})
					},
				})
				.render('#paypal-button-container')
		},
		showError(message, onClose) {
			if (message === false) {
				this.snackbar = false
			} else {
				this.error = message
				this.snackbar = true
				this.onSnackbarClose = onClose || null
			}
		},
	},
	created() {
		this.loadPaypalButton()
	},
}
</script>

<template>
	<div>
		<v-progress-linear indeterminate color="link" v-if="paypalLoading" />
		<div id="paypal-button-container"></div>
		<v-snackbar v-model="snackbar" multi-line vertical centered :timeout="-1">
			<div class="pb-2 font-2" v-if="error.title">
				<b>{{ error.title }}</b>
			</div>
			<div v-if="error.text">{{ error.text }}</div>

			<template v-slot:action="{ attrs }">
				<Button color="link" small v-bind="attrs" @click="showError(false)">
					{{ 'Entendido' | lang }}
				</Button>
			</template>
		</v-snackbar>
	</div>
</template>
