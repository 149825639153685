<script>
export default {
	lang: 'shop',
	inject: {
		xcheckout: { default: null },
	},
	props: ['order'],
	computed: {
		checkout() {
			return this.xcheckout?.()
		},
		cOrder() {
			return this.checkout?.order || this.order
		},
		couponDiscount() {
			return this.cOrder.discounts.find((discount) => discount.triggerType == 'coupon')
		},
	},
}
</script>

<template>
	<CardLayout :title="$lang('Cupón Aplicado')" v-bind="$attrs" v-on="$listeners">
		<div v-if="couponDiscount" class="d-flex align-center">
			<v-icon class="mr-1">mdi-star</v-icon>
			<span>{{ couponDiscount.discountName }}</span>
		</div>
		<div v-else>{{ 'No se aplicó cupón de descuento' | lang }}</div>
	</CardLayout>
</template>
