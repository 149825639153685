<script>
export default {
	lang: 'shop',
	inject: {
		xcheckout: { default: null },
	},
	props: ['order'],
	data() {
		return { loading: false }
	},
	computed: {
		checkout() {
			return this.xcheckout?.()
		},
		cOrder() {
			return this.checkout?.order || this.order
		},
		delivery() {
			return this.cOrder.delivery
		},
		externalData() {
			return this.delivery?.externalData || {}
		},
		isPackage() {
			return this.externalData.packageCreatedAt && !this.checkout
		},
		shouldCalculateEta() {
			return (
				this.delivery &&
				!this.isPackage &&
				this.cOrder.mainStatus == 'confirmed' &&
				this.cOrder.paymentStatus == 'pending'
			)
		},
	},
	mounted() {
		if (this.shouldCalculateEta) {
			this.$shopApi.get({
				url: `/user/order/${this.cOrder.id}/refresh-delivery-eta`,
				loading: (v) => (this.loading = v),
				onSuccess: ({ data }) => {
					this.$assign(this.order, { delivery: data.delivery })
				},
			})
		}
	},
}
</script>

<template>
	<CardLayout :title="delivery.methodName" v-bind="$attrs" v-on="$listeners" v-if="delivery">
		<v-progress-linear indeterminate color="primary" v-if="loading" />
		<template v-else>
			<component
				:is="`DeliveryOptionCardContent-${delivery.methodKey}`"
				:with-radio="false"
				:option="delivery"
				:currency="cOrder.currency.sign"
				:show-cost="false"
				:show-to-address-line="true"
			/>
			<div class="font-weight-bold pt-2">{{ 'Recibe' | lang }}</div>
			<div>
				{{ delivery.receiverFirstname }} {{ delivery.receiverLastname }} ({{ delivery.receiverIdNumber }})
			</div>
			<template v-if="isPackage && delivery.carrierName">
				<div class="font-weight-bold pt-2">{{ 'Transporte' | lang }}</div>
				<div>{{ delivery.carrierName }}</div>
			</template>
			<template v-if="isPackage && externalData.trackingCode">
				<div class="font-weight-bold pt-2">{{ 'Código de seguimiento' | lang }}</div>
				<div>{{ externalData.trackingCode }}</div>
			</template>
			<template v-if="isPackage && externalData.trackingUrl">
				<div class="font-weight-bold pt-2">
					{{ 'Utilizá el código para ver el estado de tu entrega ingresando aquí' | lang }}
				</div>
				<div>
					<a :href="externalData.trackingUrl">{{ externalData.trackingUrl }}</a>
				</div>
			</template>
		</template>
	</CardLayout>
</template>
