<script>
export default {
	lang: 'shop',
	props: {
		order: Object,
	},
	data() {
		return {
			loading: false,
		}
	},
	methods: {
		payOrder() {
			this.$shopApi.get(`/order/${this.order.id}/resolve-payment`, {
				loading: (v) => (this.loading = v),
				onSuccess: ({ data, options }) => {
					if (data?.redirectWithForm) {
						this.$redirectWithForm(data.redirectWithForm)
					}
				},
				onError: (err) => {
					console.log(err)
				},
			})
		},
	},
	created() {
		console.log('created')
	},
}
</script>

<template>
	<div>
		<div
			class="font-2"
			v-html="
				$lang(
					'<b>Si aún no has realizado el pago</b> o quieres volver a intentarlo, <b>haz click en el siguiente botón</b> y serás redirigido a la pantalla de pago.'
				)
			"
		></div>
		<div class="py-5">
			<Button color="success" :loading="loading" @click="payOrder">
				{{ 'Realizar pago' | lang }}
			</Button>
		</div>
		<div
			class="font-1"
			v-html="
				$lang(
					'Te solicitamos que <b>realices el pago dentro de las 72 hs.</b> de haber confirmado tu compra. Luego de ese plazo tu orden será cancelada.'
				)
			"
		></div>
	</div>
</template>
