<script>
import addressHelperMixin from '@/__shared/shop/mixin_address-helper.js'

export default {
	lang: 'shop',
	mixins: [addressHelperMixin],
	inject: {
		xcheckout: { default: null },
	},
	props: ['order'],
	computed: {
		checkout() {
			return this.xcheckout?.()
		},
		cOrder() {
			return this.checkout?.order || this.order
		},
		buyer() {
			return this.cOrder.buyer
		},
		invoice() {
			return this.cOrder.invoice
		},
		invoiceAddress() {
			return this.cOrder.invoiceAddress
		},
	},
}
</script>

<template>
	<CardLayout :title="$lang('Contacto y Facturación')" v-bind="$attrs" v-on="$listeners">
		<v-row>
			<v-col cols="12">
				<div class="font-weight-bold pb-3">{{ 'Datos de Contacto' | lang }}</div>
				<div>
					{{ buyer.firstname }} {{ buyer.lastname }}<br />
					{{ buyer.email }}<br />
					{{ buyer.fullPhone }}
				</div>
			</v-col>
			<v-col cols="12">
				<div class="font-weight-bold pb-3">{{ 'Datos de Facturación' | lang }}</div>
				<div v-if="invoice.business && invoice.invoiceType == 'E'">
					{{ invoice.businessName }}<br />
					{{ makeAddressLine(invoiceAddress) }}
				</div>
				<div v-else-if="invoice.business">
					{{ invoice.businessName }}<br />
					CUIT {{ invoice.businessIdNumber }}<br />
					{{ 'Factura' | lang }} {{ invoice.invoiceType }}<br />
					{{ makeAddressLine(invoiceAddress) }}
				</div>
				<div v-else>
					{{ invoice.personLastname }}, {{ invoice.personFirstname }}<br />
					DNI {{ invoice.personIdNumber }}<br />
					{{ makeAddressLine(invoiceAddress) }}
				</div>
			</v-col>
		</v-row>
	</CardLayout>
</template>
