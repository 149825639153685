<script>
import { brandsLogos } from './brands-logos'
export default {
	lang: 'shop',
	props: {
		order: Object,
		brand: Object,
		entity: Object,
		plan: Object,
		actionText: String,
	},
	computed: {
		brandsLogos() {
			return brandsLogos
		},
		isMobile() {
			return this.$vuetify.breakpoint.xs
		},
	},
}
</script>

<template>
	<v-card elevation="2" @click="$emit('click')">
		<div class="d-flex align-start pa-4">
			<div class="flex-grow-0 mr-3">
				<div class="pa-2" style="border: 1px solid #ccc; border-radius: 4px">
					<Media
						:src="brandsLogos[brand.code]"
						:width="isMobile ? 45 : 55"
						:height="isMobile ? 45 : 55"
						contain
					/>
				</div>
			</div>
			<div>
				<div>
					<b>{{ brand.name }}</b>
				</div>
				<div class="grey--text font-0 pb-1">
					{{ entity.displayName }}
				</div>
				<div class="d-flex align-center">
					<b class="nowrap">
						{{ plan.qty }}{{ brand.type == 'debit' ? ' pago de ' : 'x' }}
						<PriceText
							:amount="plan.calc.installmentsAmount"
							:currency="order.currency.sign"
							:sup-decimals="false"
						/>
					</b>
					<div v-if="plan.specialPlanName || plan.specialPlanLogo" class="d-flex align-center ml-2">
						<Media v-if="plan.specialPlanLogo" width="80" height="30" :src="plan.specialPlanLogo" />
						<div
							v-if="plan.specialPlanName"
							class="font-0 grey--text text--darken-2 font-weight-bold"
							:class="{ 'ml-1': !!plan.specialPlanLogo }"
						>
							{{ plan.specialPlanName }}
						</div>
					</div>
				</div>
				<div class="success--text font-0 nowrap" v-if="plan.qty > 1 && !plan.surcharge">
					{{ 'Sin Interés' | lang }}
				</div>
				<div class="grey--text font-0 nowrap" v-else-if="plan.qty > 1">
					{{ 'Total' | lang }}
					<PriceText
						:amount="plan.calc.totalAmount"
						:currency="order.currency.sign"
						:sup-decimals="false"
					/>
				</div>
			</div>
			<v-spacer />
			<div class="action-btn-cont" v-if="!isMobile">
				<Button color="link" text small @click.stop="$emit('click:action')">
					{{ actionText || $lang('Modificar') }}
				</Button>
			</div>
		</div>
		<div v-if="isMobile" class="pt-1">
			<Button block color="link" small @click.stop="$emit('click:action')">
				{{ actionText || $lang('Modificar') }}
			</Button>
		</div>
	</v-card>
</template>

<style scoped>
.action-btn-cont {
	align-self: start;
}
</style>
