<script>
import getRejectionError from './credit-card-rejection-error'
import moment from 'moment'

export default {
	lang: 'shop',
	props: {
		order: Object,
		brand: Object,
		entity: Object,
		plan: Object,
		savedForm: Object,
	},
	model: {
		prop: 'savedForm',
		event: 'updateSavedForm',
	},
	data() {
		return {
			form: {
				number: null,
				payerName: null,
				payerIdNumber: null,
				expirationMonth: null,
				expirationYear: null,
				cvv: null,
				...this.savedForm,
			},
			formValid: false,
			rules: {
				required: () => (value) => {
					value = String(value || '').trim()
					return value != '' ? true : this.$lang('Requerido')
				},
				maxLen: (max) => (value) => {
					value = String(value || '').trim()
					return value.length <= max ? true : this.$lang(`Máximo {max} caracteres`, { max })
				},
				numLen: (len) => (value) => {
					value = String(value || '').trim()
					return value.length == len ? true : this.$lang(`Ingresa {len} dígitos`, { len })
				},
				ccnumber: () => (value) => {
					value = String(value || '').replace(/\s+/g, '')
					if (!this.brand || !value) return true
					if (!value.match(/^[0-9]+$/)) return this.$lang('Ingresa solo números')
					if (!value.match(this.brand.pattern)) {
						return this.$lang(`El número no corresponde a una Tarjeta {brand}`, {
							brand: this.brand.name,
						})
					}
					return true
				},
				ccnumLen: (brand) => (value) => {
					value = String(value || '').replace(/\s+/g, '')
					if (brand.ccnumLen.includes('-')) {
						let [from, to] = brand.ccnumLen.split('-')
						if (value.length >= Number(from) && value.length <= Number(to)) return true
						return this.$lang(
							`El número de tu Tarjeta {brand} debe tener entre {from} y {to} dígitos`,
							{
								brand: brand.name,
								from,
								to,
							}
						)
					} else {
						if (value.length == Number(brand.ccnumLen)) return true
						return this.$lang(`El número de tu Tarjeta {brand} debe tener {len} dígitos`, {
							brand: brand.name,
							len: brand.ccnumLen,
						})
					}
				},
				dni: () => (value) => {
					value = String(value || '').trim()
					if (!value) return true
					return value.match(/^[1-9]{1}[0-9]{6,7}$/) ? true : this.$lang('El DNI es inválido')
				},
				payerName: () => (value) => {
					value = String(value || '').trim()
					if (!value) return true
					return value.match(/^[a-záéíóúñ]+(\s+[a-záéíóúñ]+)+$/i) ? true : this.$lang('Nombre inválido')
				},
				numeric: () => (value) => {
					value = String(value || '').trim()
					if (!value) return true
					return value.match(/^[0-9]+$/) ? true : this.$lang('Ingresa solo números')
				},
				month: () => (value) => {
					let m = String(value || '').trim()
					return m.match(/^(0[1-9]{1}|1[0-2]{1})$/) ? true : this.$lang('Mes inválido')
				},
				year: () => (value) => {
					let y = String(value || '').trim()
					if (!y.match(/^(2[0-9]{3}|[0-9]{2})$/)) {
						return this.$lang('Año inválido')
					}
					y = Number(y)
					if (y >= 2000) y -= 2000
					let thisYear = Number(moment().format('YY'))
					return y >= thisYear && y <= thisYear + 50 ? true : this.$lang('Año inválido')
				},
			},
			rejectionCode: null,
			submitLoading: false,
			operatorSubmitData: null,
			operatorKey: null,
			invalidPlan: false,
		}
	},
	computed: {
		rejectionError() {
			return this.rejectionCode && getRejectionError(this.rejectionCode)
		},
		canSubmit() {
			return !!(this.brand && this.entity && this.plan && this.formValid)
		},
		cvvPlaceholder() {
			if (!this.brand) return ''
			return ''.padStart(this.brand.cvvLen, 'X')
		},
		creditCardNumberPlaceholder() {
			if (!this.brand) return ''
			return ''.padStart(
				this.brand.ccnumLen.includes('-') ? this.brand.ccnumLen.split('-')[0] : this.brand.ccnumLen,
				'X'
			)
		},
		isMobile() {
			return this.$vuetify.breakpoint.xs
		},
	},
	watch: {
		form: {
			deep: true,
			handler(value) {
				this.$emit('updateSavedForm', value)
			},
		},
		'form.number'() {
			this.formatCardNumber()
		},
	},
	methods: {
		normalizePayerName() {
			return this.form.payerName
				.trim()
				.replace(/\s+/g, ' ')
				.toUpperCase()
				.replace(/Á/g, 'A')
				.replace(/É/g, 'E')
				.replace(/Í/g, 'I')
				.replace(/Ó/g, 'O')
				.replace(/Ú/g, 'U')
		},
		normalizeExpirationYear() {
			let expirationYear = Number(this.form.expirationYear)
			if (expirationYear > 2000) expirationYear -= 2000
			return String(expirationYear).trim()
		},
		getNormalizedForm() {
			return {
				number: this.form.number.replace(/\s+/g, ''),
				expirationMonth: this.form.expirationMonth.trim(),
				expirationYear: this.normalizeExpirationYear(),
				payerName: this.normalizePayerName(),
				payerIdNumber: this.form.payerIdNumber.trim(),
				cvv: this.form.cvv.trim(),
			}
		},
		async submitPayment() {
			if (this.invalidPlan) {
				this.$emit('reset-plan')
				return
			}

			if (!this.$refs.form.validate()) return
			this.operatorSubmitData = null
			this.operatorKey = null
			this.rejectionCode = null
			this.submitLoading = true
			let form = this.getNormalizedForm()
			this.$shopApi.post({
				url: `/credit-card/submit-payment/${this.order.id}`,
				data: {
					brandId: this.brand.id,
					entityId: this.entity.id,
					planId: this.plan.id,
					ccbin: form.number.slice(0, 6),
					lastFourDigits: form.number.slice(form.number.length - 4),
					expirationMonth: form.expirationMonth,
					expirationYear: form.expirationYear,
					payerName: form.payerName,
					payerIdNumber: form.payerIdNumber,
					surcharge: this.plan.surcharge,
				},
				loading: false,
				done: ({ data, success, message }) => {
					if (success) {
						this.operatorSubmitData = data.operatorSubmitData
						this.operatorKey = data.operatorKey
					} else {
						if (message.code == 'PAYMENT_NOT_ALLOWED') {
							if (data.order) {
								return this.$router.push({ name: 'user.order-detail', params: { id: data.order.id } })
							} else {
								return this.$router.push({ name: 'user.orders' })
							}
						}
						if (message.code == 'INVALID_PLAN') {
							this.invalidPlan = true
							this.$emit('invalid-plan')
						}
						this.rejectionCode = message.code
						this.submitLoading = false
					}
				},
			})
		},
		onResolverResponse(status, data) {
			switch (status) {
				case 'approved':
				case 'invalid':
					this.$router.push({ name: 'user.order-detail', params: { id: this.order.id } })
					break
				case 'rejected':
					this.rejectionCode = data.rejectionCode
					this.submitLoading = false
					break
			}
		},
		formatCardNumber() {
			let cleanValue = String(this.form.number || '').replace(/\s+/g, '')
			if (this.brand?.ccnumLen) {
				let spl = this.brand.ccnumLen.split('-')
				let max = parseInt(spl[1] || spl[0])
				cleanValue = cleanValue.slice(0, max)
			}

			let formatedValue = ''
			if (this.brand?.numberFormat) {
				let c = 0
				for (let n of this.brand.numberFormat.split('-')) {
					n = parseInt(n)
					let p = cleanValue.slice(c, c + n)
					if (p) formatedValue += p + '  '
					c += n
				}
			} else {
				formatedValue = cleanValue
			}

			formatedValue = formatedValue.trim()

			this.$nextTick(() => {
				this.form.number = formatedValue.trim()
			})
		},
	},
	created() {
		this.formatCardNumber()
	},
}
</script>

<template>
	<div>
		<Container :width="{ sm: 480 }" :fluid="{ xs: true, sm: false }">
			<v-form v-model="formValid" ref="form" @submit.prevent="submitPayment">
				<v-row dense>
					<v-col cols="12">
						<TextField
							id="card-number"
							class="card-number"
							:outlined="true"
							dense
							type="tel"
							v-model="form.number"
							:rules="[rules.required(), rules.ccnumber(), rules.ccnumLen(brand)]"
							:label="$lang('Número de Tarjeta')"
							:hide-details="false"
							validate-on-blur
						/>
					</v-col>
					<v-col cols="12" sm="6">
						<TextField
							id="card-holder-name"
							class="card-holder-name"
							:outlined="true"
							dense
							v-model="form.payerName"
							:rules="[rules.required(), rules.maxLen(30), rules.payerName()]"
							:label="$lang('Titular de la Tarjeta')"
							:placeholder="$lang('JUAN A PEREZ')"
							maxlength="30"
							:hide-details="false"
							validate-on-blur
						/>
					</v-col>
					<v-col cols="12" sm="6">
						<TextField
							id="card-holder-id"
							class="card-holder-id"
							type="tel"
							:outlined="true"
							dense
							v-model="form.payerIdNumber"
							:rules="[rules.required(), rules.numeric(), rules.dni()]"
							ref="test"
							:label="$lang('DNI del titular')"
							placeholder="22333444"
							maxlength="8"
							:hide-details="false"
							validate-on-blur
						/>
					</v-col>
					<v-col cols="6" sm="3">
						<TextField
							id="card-expiration-month"
							class="card-expiration-month"
							type="tel"
							:outlined="true"
							dense
							v-model="form.expirationMonth"
							:rules="[rules.required(), rules.numeric(), rules.month()]"
							:label="$lang('Mes')"
							:placeholder="$lang('MM')"
							maxlength="2"
							ref="expMonth"
							:hide-details="false"
							validate-on-blur
						/>
					</v-col>
					<v-col cols="6" sm="3">
						<TextField
							id="card-expiration-year"
							class="card-expiration-year"
							type="tel"
							:outlined="true"
							dense
							v-model="form.expirationYear"
							:rules="[rules.required(), rules.numeric(), rules.year()]"
							:label="$lang('Año')"
							:placeholder="$lang('AA')"
							:hide-details="false"
							validate-on-blur
						/>
					</v-col>
					<v-col cols="12" sm="6">
						<TextField
							id="card-cvc"
							class="card-cvc"
							type="tel"
							:outlined="true"
							dense
							v-model="form.cvv"
							:rules="[rules.required(), rules.numeric(), rules.numLen(brand.cvvLen)]"
							:label="$lang('CVV')"
							:placeholder="cvvPlaceholder"
							:maxlength="brand.cvvLen"
							:hide-details="false"
							validate-on-blur
						/>
					</v-col>
				</v-row>
			</v-form>
			<v-row v-if="rejectionError">
				<v-col>
					<v-alert class="mb-0" text prominent type="error" icon="mdi-alert">
						<div class="font-1" v-if="rejectionError.title">
							<b>{{ rejectionError.title }}</b>
						</div>
						<div class="font-1 mt-2" v-if="rejectionError.text" style="white-space: pre-line">
							{{ rejectionError.text }}
						</div>
					</v-alert>
				</v-col>
			</v-row>
			<v-row>
				<v-col class="d-flex justify-end">
					<Button
						class="mt-3"
						color="success"
						@click="submitPayment"
						:loading="submitLoading"
						large
						block
					>
						{{ invalidPlan ? $lang('Modificar Plan de Pagos') : $lang('Realizar pago') }}
					</Button>
				</v-col>
			</v-row>
		</Container>
		<div v-if="operatorSubmitData && operatorKey">
			<component
				:is="`CreditCard-OperatorResolver-${operatorKey}`"
				:data="operatorSubmitData"
				:credit-card-form="getNormalizedForm()"
				:brand="brand"
				:entity="entity"
				:plan="plan"
				:order="order"
				@rejected="onResolverResponse('rejected', $event)"
				@invalid="onResolverResponse('invalid', $event)"
				@approved="onResolverResponse('approved', $event)"
			/>
		</div>
	</div>
</template>

<style scoped>
.v-text-field ::v-deep .v-messages__message {
	font-size: 13px;
	color: var(--v-error-base);
	background: var(--v-error-lighten5);
	padding: 4px 6px;
	border-radius: 6px;
	display: inline-block;
	font-weight: bold;
}
.v-text-field ::v-deep .v-text-field__details {
	padding: 0 !important;
}

.card-number ::v-deep input {
	letter-spacing: 2px;
}
.card-holder-name ::v-deep input {
	text-transform: uppercase;
}
</style>
