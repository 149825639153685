import _ from 'lodash'
import { $lang } from '@/__shared/lang/lib_lang'

export default {
	methods: {
		makeAddressLine(address) {
			let line = [
				address.zipcode.state.country.name,
				address.zipcode.code,
				address.zipcode.state.name,
				address.city,
				`${address.street} ${address.streetNumber}`,
			]

			if (address.floor) {
				line.push($lang('site|addressHelper', `Piso {floor}`, { floor: address.floor }))
			}
			if (address.apartment) {
				line.push($lang('site|addressHelper', `Dpto {apartment}`, { apartment: address.apartment }))
			}

			if (address.intersection1 || address.intersection2) {
				let str = [address.intersection1, address.intersection2].filter((x) => !!x).join(' & ')
				line.push($lang('site|addressHelper', 'Entrecalles') + `: ${str}`)
			}

			if (address.comment) {
				line.push($lang('site|addressHelper', 'Comentarios') + `: ${address.comment}`)
			}
			return line.join(', ')
		},
		makeCustomAddressLine(address, itemsTemplates, sepBy = ', ') {
			let items = []
			for (let itemTmp of itemsTemplates) {
				let phs = itemTmp.match(/#[a-zA-Z0-9.]+/g)
				if (!phs) {
					items.push(itemTmp)
				} else {
					let reps = []
					for (let ph of phs) {
						let value = String(_.get(address, ph.slice(1)) || '').trim()
						reps.push({ ph, value })
					}
					if (!reps.find(({ value }) => !!value)) continue
					let item = itemTmp
					reps.forEach(({ ph, value }) => (item = item.replace(ph, value)))
					items.push(item.trim())
				}
			}

			return items.join(sepBy)
		},
	},
}
