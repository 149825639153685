<script>
export default {
	props: {
		option: Object,
	},
}
</script>

<template>
	<DeliveryOptionCardContent v-on="$listeners" v-bind="$attrs" :option="option" />
</template>
