<script>
export default {
	lang: 'shop',
	metaInfo() {
		return {
			title: this.title ? `${this.title} | Checkout` : 'Checkout',
		}
	},
	props: {
		title: String,
		showMobileExpandedSummary: Boolean,
	},
	inject: ['xcheckout'],
	computed: {
		checkout() {
			return this.xcheckout()
		},
		showCollapsedSummary() {
			return this.$vuetify.breakpoint.smAndDown && !this.showMobileExpandedSummary
		},
	},
}
</script>

<template>
	<div>
		<Container class="pb-16" :max-width="{ xs: '100%', sm: '90%' }">
			<v-row>
				<v-col cols="12" md="7" class="pt-5">
					<div class="mb-4" v-if="checkout.loadingStep">
						<v-skeleton-loader loading type="heading" />
					</div>
					<div class="font-4 mb-4" v-else>
						{{ title }}
					</div>
					<v-divider class="mb-4" />
					<div v-if="checkout.loadingStep">
						<v-card tile class="mb-4 pa-5">
							<v-skeleton-loader loading type="heading" class="mb-6" />
							<v-skeleton-loader loading type="list-item-avatar" />
							<v-skeleton-loader loading type="list-item-avatar" />
						</v-card>
					</div>
					<slot v-else />
				</v-col>
				<v-col cols="12" md="5" class="pt-5">
					<div v-if="checkout.loadingStep">
						<v-skeleton-loader loading type="heading" class="mb-6" />
						<v-card tile class="mb-4 pa-5">
							<v-skeleton-loader loading type="heading" class="mb-6" />
							<v-skeleton-loader loading type="list-item-avatar@6" />
						</v-card>
					</div>
					<template v-else>
						<CheckoutCollapsedSummary v-if="showCollapsedSummary" :order="checkout.order">
							<template #below-summary>
								<slot name="below-summary" />
							</template>
						</CheckoutCollapsedSummary>
						<template v-else>
							<div class="font-3 mb-4">
								{{ 'Resumen de compra' | lang }}
							</div>
							<v-divider class="mb-4" />
							<OrderSummary :order="checkout.order" />
							<slot name="below-summary" />
						</template>
					</template>
				</v-col>
			</v-row>
		</Container>
		<v-overlay :value="checkout.loadingSubmit" z-index="999999">
			<v-progress-circular indeterminate size="80" />
		</v-overlay>
	</div>
</template>
<style></style>
