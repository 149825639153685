<script>
export default {
	props: ['order', 'checkout'],
}
</script>

<template>
	<div v-if="order.paymentStatus == 'paid'">
		<CreditCardTransactionDetail :order="order" />
	</div>
	<div v-else>
		<CreditCardCheckoutOptionContent v-bind="{ order, checkout }" />
	</div>
</template>
