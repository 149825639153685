<script>
import { brandsLogos } from './brands-logos'
export default {
	lang: 'shop',
	props: {
		order: Object,
	},
	computed: {
		brandsLogos() {
			return brandsLogos
		},
		transaction() {
			return this.order.payments[0].data
		},
	},
}
</script>

<template>
	<div class="d-flex">
		<div class="mr-2">
			<div class="brand-logo-cont">
				<Media :src="brandsLogos[transaction.brand.code]" height="100%" width="100%" contain eager />
			</div>
		</div>
		<div class="px-4 font-1">
			<div class="pb-2 font-2">
				<b>{{ transaction.qty }}x </b>
				<PriceText
					:amount="transaction.installmentsAmount"
					zero-decimals
					:sup-decimals="false"
					:currency="order.currency.sign"
				/>
			</div>
			<div class="pb-2 grey--text text--darken-2">
				{{ 'Total' | lang }}
				<PriceText
					:amount="transaction.totalAmount"
					zero-decimals
					:sup-decimals="false"
					:currency="order.currency.sign"
				/>
			</div>
			<div class="pb-2">
				<b>{{ $lang('Tarjeta {brand}', { brand: transaction.brand.name }) }}</b>
				{{ 'terminada en' | lang }}
				<b>{{ transaction.lastFourDigits }}</b>
			</div>
			<div class="success--text">
				<b>{{ 'Pago aprobado' | lang }}</b>
			</div>
		</div>
	</div>
</template>

<style scoped>
.brand-logo-cont {
	width: 80px;
	height: 80px;
	padding: 16px;
	border-radius: 100%;
	background-color: #eee;
}
</style>
