<script>
import { get } from 'vuex-pathify'
export default {
	lang: 'shop',
	inject: ['xcheckout'],
	data() {
		return {
			activeForm: 'signup',
		}
	},
	computed: {
		checkout() {
			return this.xcheckout()
		},
		user: get('shop/user'),
	},
	watch: {
		user(value) {
			if (value) {
				this.checkout.stepSubmit()
			}
		},
	},
}
</script>

<template>
	<CheckoutStepLayout :title="$lang('Ingresá o Registrate')">
		<CardLayout>
			<div class="px-md-4">
				<LoginForms v-model="activeForm" />
			</div>
		</CardLayout>
	</CheckoutStepLayout>
</template>
