<script>
export default {
	lang: 'shop',
	inject: {
		xcheckout: { default: null },
	},
	props: ['order'],
	computed: {
		checkout() {
			return this.xcheckout?.()
		},
		cOrder() {
			return this.checkout?.order || this.order
		},
		payment() {
			return this.cOrder.payments[0]
		},
		hasPayment() {
			return this.payment?.methodKey != 'Void'
		},
	},
}
</script>

<template>
	<CardLayout :title="$lang('Forma de Pago')" v-bind="$attrs" v-on="$listeners" v-if="hasPayment">
		<component
			:is="`${payment.methodKey}-Payment-CheckoutConfirmCardContent`"
			:order="cOrder"
			:checkout="checkout"
		/>
	</CardLayout>
</template>
